import lozad from 'lozad';
import ResizeObserver from 'resize-observer-polyfill';

export function initTileSizing() {
  // console.log('initTileSizing was run');
  const ro = new ResizeObserver((entries, observer) => {
    for (const entry of entries) {
      const { left, top, width, height } = entry.contentRect;
      const img = entry.target.querySelector('img');
      img.setAttribute('width', width);
      img.setAttribute('height', height);
    }
  });

  var tiles = [].slice.call(document.querySelectorAll('.ProductionCardList-item'));
  tiles.forEach(function(tile) {
    ro.observe(tile);
  });
}

export function initTileLoading() {
  // console.log('initTileLoading was run');
  const intersectionObserver = lozad('.ProductionCard-image', {
    load: function(el) {
      el.src = el.getAttribute('data-src');
      el.classList.remove('js-hide');
    }
  });
  intersectionObserver.observe();
}
