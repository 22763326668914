import Headroom from 'headroom.js';

export default function initHeadroom() {
  const header = document.querySelector('header');
  const options = {
    offset: 32,
    classes: {
      // when element is initialised
      initial: 'Headroom',
      // when scrolling up
      pinned: 'Headroom--pinned',
      // when scrolling down
      unpinned: 'Headroom--unpinned',
      // when above offset
      top: 'Headroom--top',
      // when below offset
      notTop: 'Headroom--not-top',
      // when at bottom of scoll area
      bottom: 'Headroom--bottom',
      // when not at bottom of scroll area
      notBottom: 'Headroom--not-bottom'
    }
  };
  const headroom = new Headroom(header, options);
  headroom.init();
}
