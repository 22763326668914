// All in Pictures

import { initTileSizing, initTileLoading } from './tiles';
import initLazyLoadImages from './lazy_load_images';
import initHeadroom from './ai_headroom.js';
import initNav from './nav.js';

// detect if there is a touch-screen present, grayscale filter is only for mouseover
if ('ontouchstart' in document.documentElement) {
  document.documentElement.classList.add('touch');
} else {
  document.documentElement.classList.add('no-touch');
}

document.addEventListener('DOMContentLoaded', function() {
  initTileSizing();
  initLazyLoadImages();
  initHeadroom();
  initNav();
});

document.addEventListener('readystatechange', event => {
  if (event.target.readyState === 'complete') {
    // console.log('CSS is loaded thus grid is calculated');
    initTileLoading();
  }
});

import { listen } from 'quicklink';
listen();
