function toggleNav() {
  const menu = document.getElementById('menu');
  const nav = document.getElementById('nav');
  const container = document.getElementById('container');

  if (menu.getAttribute('aria-expanded') === 'false') {
    menu.setAttribute('aria-expanded', 'true');
    container.addEventListener('click', toggleNav, false);
  } else {
    menu.setAttribute('aria-expanded', 'false');
    container.removeEventListener('click', toggleNav, false);
  }
  menu.classList.toggle('is-open');
  nav.classList.toggle('is-open');
  container.classList.toggle('is-squished');
}

export default function initNav() {
  const menu = document.getElementById('menu');
  menu.addEventListener('click', toggleNav, false);
}
